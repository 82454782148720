/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap'); */

@font-face {
  font-family: "Poppins";
  src: url("Components/Assets/Fonts/Poppins-Light.ttf") format("truetype"),
    url("Components/Assets/Fonts/Poppins-Light.svg#Poppins-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("Components/Assets/Fonts/Poppins-Regular.ttf") format("truetype"),
    url("Components/Assets/Fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("Components/Assets/Fonts/Poppins-SemiBold.ttf") format("truetype"),
    url("Components/Assets/Fonts/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("Components/Assets/Fonts/Poppins-Bold.ttf") format("truetype"),
    url("Components/Assets/Fonts/Poppins-Bold.svg#Poppins-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

*:focus {
  outline: none;
}

body {
  font-size: 14px !important;
  line-height: 1.4;
  color: #000;
  background: #fff;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100% !important;
}
@media (max-width: 768px) {
  body {
    font-size: 13px !important;
  }
}
@media (max-width: 576px) {
  body {
    font-size: 12px !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: unset;
}

h1,
.h1 {
  font-size: 3em; /* 40px */
}

h2,
.h2 {
  font-size: 2.286em; /* 32px */
}

h3,
.h3 {
  font-size: 1.715em; /* 24.08px */
}

h4,
.h4 {
  font-size: 1.43em; /* 20.02px */
}

h5,
.h5 {
  font-size: 1.29em; /* 18.06px */
}

h6,
.h6 {
  font-size: 1.143em; /* 16px */
}

p,
.p,
.psmall,
.pxsmall,
.pxxsmall {
  margin: 0;
}

p,
.p {
  font-size: 1em;
}

.psmall {
  font-size: 0.95em; /* 13.3px */
}

.pxsmall {
  font-size: 0.85em; /* 11.2px */
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.text-primary {
  color: #000a8c !important;
}

a {
  color: #000a8c !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.invisibility {
  position: absolute;
  left: -99999px;
  top: -99999px;
}
.data-null {
  margin: auto;
  padding: 50px;
  font-weight: bold;
  color: #aaa;
}
.button-card {
  display: block;
  width: 100%;
  background: transparent;
  border: unset;
  padding: 0;
  transition: all 0.25s ease;
}
.button-card:focus {
  outline: unset;
  box-shadow: unset;
}
.button-card:hover {
  transform: translateY(-3px);
  transition: all 0.25s ease;
}
@media (max-width: 450px) {
  .btn {
    font-size: 14px !important;
  }
  input,
  label {
    font-size: 14px !important;
  }
}
button:focus {
  outline: unset;
  box-shadow: unset;
}
.tox-statusbar {
  display: none !important;
}
.tox .tox-editor-header {
  z-index: 0 !important;
}
label {
  color: #4a4a4a;
}
@media (max-width: 767px) {
  .footer-product {
    margin-bottom: 75px;
  }
}
@media (max-width: 575px) {
  .hiddenScroll {
    overflow: hidden;
  }
}
.form-control {
  font-size: 14px;
}
.modal-title {
  font-size: 16px;
}
